import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../styles/ForthSec.css';
import '../styles/Main.css';
import { Col, Row } from 'react-bootstrap';

function ForthSec() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    margin:20,
    centerMode: true,
    centerPadding: '25%',

    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '25%',
        }
      }
    ]
  };

  const slides = [
    { id: 1, image: require('../assets/VALISTUS.jpeg'), text: 'VALISTUS' },
    { id: 2, image: require('../assets/HARVESTINTERNATIONALSCHOOL.jpeg'), text: 'HARVEST INTERNATIONAL SCHOOL' },
    { id: 3, image: require('../assets/LORDINTERNATIONALRESIDENTIALSCHOOL.webp'), text: 'LORD INTERNATIONAL RESIDENTIAL SCHOOL' },
    { id: 4, image: require('../assets/RAKSULUBCITY.jpg'), text: 'RAKSUL UB CITY' },
    { id: 5, image: require('../assets/EDIFY.jpeg'), text: 'EDIFY' },
    { id: 6, image: require('../assets/DECCANINTERNATIONAL.jpg'), text: 'DECCAN INTERNATIONAL' },
    { id: 7, image: require('../assets/RVCOLLEGE.jpeg'), text: 'R V COLLEGE LIBRARY' },
    { id: 8, image: require('../assets/SERVESPACEOFFICE.jpg'), text: 'SERVESPACE OFFICE' },
    { id: 9, image: require('../assets/MOENGAGEOFFICE.jpeg'), text: 'MOENGAGE OFFICE' },
    { id: 10, image: require('../assets/RIGELOFFICE.jpg'), text: 'RIGEL OFFICE' },
    // Add more slides as needed
  ];


  return (
    <>
        <Row className='frsRow' id='Projects'>
            <Col className='col-12 col-md-7 mx-auto'>
            <center>
            <p className='heading-paragraph'>OUR PROJECT</p>
            <h2 className='heading-title-light'>Our Interior Designing Projects </h2>
            {/* <p className='paragraph-16-light'>Explore finished kitchens from some of our customers and see how they created their dream kitchen while getting ideas for your own.</p> */}
            </center>
            </Col>
            <Slider {...settings}>
      {slides.map((slide) => (
        <div key={slide.id}>
        <img
            src={slide.image}
            alt={`Slide ${slide.id}`}
          
            className='sliderImages'
          />
          <p style={{color:'#fff',textAlign:'center'}}>{slide.text}</p>
        </div>
      ))}
    </Slider>
        </Row>
        
    </>
  )
}

export default ForthSec