import React from 'react'
import '../styles/Services.css';
import '../styles/Main.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Col, Row } from 'react-bootstrap';
function Services() {

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        margin:20,
        
    
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
              
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
              centerPadding: '25%',
            }
          }
        ]
      };
    
      const slides = [
        { id: 1, image: require('../assets/work.jpg'), text: 'Office Interior Design ' },
        { id: 2, image: require('../assets/dental.jpg'), text: 'Hospitality Interior Design' },
        { id: 3, image: require('../assets/restaurant.jpg'), text: 'Restaurant Interior Design' },
        { id: 4, image: require('../assets/school.jpg'), text: 'School Interior Designing Services' },
        { id: 5, image: require('../assets/education.jpg'), text: 'Educational Spaces' },
        { id: 6, image: require('../assets/gym.jpg'), text: 'Gym Interior Services' },
        { id: 7, image: require('../assets/work.jpg'), text: 'Corporate Interior Design ' },
        { id: 8, image: require('../assets/salon.jpg'), text: 'Saloon Interior Services' },
        { id: 9, image: require('../assets/dental.jpg'), text: 'Dental Clinic Interior Design' },
        { id: 9, image: require('../assets/trunkey.jpg'), text: 'Turnkey Interior Solutions' },
       
        // Add more slides as needed
      ];
  return (
    <>
         <Row className='ssftsRow' id='services'>
    
    <Col className='col-12 col-md-9'>
    <p className='heading-paragraph'>OUR SERVICES</p>
    <h2 className='heading-title'>Our Commercial Interior Designing Services</h2>

    </Col>
    <Slider {...settings}>
      {slides.map((slide) => (
        <div key={slide.id}>
        <img
            src={slide.image}
            alt={`Slide ${slide.id}`}
          
            className='sliderImages'
          />
          <p style={{color:'#111',textAlign:'center',fontWeight:'bold'}}>{slide.text}</p>
        </div>
      ))}
    </Slider>
</Row>
    </>
  )
}

export default Services