import React from 'react';
import '../styles/Main.css';
import '../styles/Faq.css';
import { Col, Row, Accordion } from 'react-bootstrap';

function Faq() {
  return (
    <>
        <Row className='faqRow'>
            <Col className='col-12 col-md-4 faqFirstCol p-0 p-md-1'>
                <img src={require('../assets/faq_imge.jpg')} className='faqImg' />
            </Col>
            <Col className='col-12 col-md-8 faqFirstSecond'>
            <h2 className='headingTitle'>FAQ's</h2>
            <Accordion defaultActiveKey="0" flush alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header><b className='text-dark' style={{fontSize:'1.2em'}}>What is the difference between commercial and residential interior design?</b></Accordion.Header>
        <Accordion.Body>
         <p className='text-secondary fs-5'>Commercial interior design focuses on creating functional and aesthetically pleasing spaces for businesses, such as offices, retail stores, restaurants, and schools. Residential design, on the other hand, is all about creating comfortable and livable spaces for homes. Commercial spaces typically have a higher focus on traffic flow, functionality, and brand identity, while residential spaces prioritise personal comfort and aesthetics.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><b className='text-dark' style={{fontSize:'1.2em'}}>What are the different types of commercial interior design projects?</b></Accordion.Header>
        <Accordion.Body>
         <p className='text-secondary fs-5'>There are many different types of commercial interior design projects, but some of the most common include:<br/>
<b>Office interior design:</b> Creating productive and collaborative workspaces for businesses of all sizes.<br/>
<b>Retail interior design:</b> Designing stores that are both visually appealing and functional for customers.<br/>
<b>Restaurant interior design:</b> Creating a unique and inviting dining experience for guests.<br/>
<b>Healthcare interior design:</b> Designing hospitals, clinics, and other healthcare facilities that are both functional and patient-centred.<br/>
<b>School interior design:</b> Creating safe and stimulating learning environments for students of all ages.<br/>
</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><b className='text-dark' style={{fontSize:'1.2em'}}>What are the benefits of using a turnkey interior solution?</b></Accordion.Header>
        <Accordion.Body>
         <p className='text-secondary fs-5'><b>One-stop shop:</b> You deal with a single point of contact for design, execution, and project management, simplifying communication and reducing stress.<br/>
<b>Streamlined process:</b> Turnkey providers manage construction, procurement, and installation, ensuring smoother project timelines and fewer delays.<br/>
<b>Expertise:</b> Leverage their experience and network for better pricing, material selection, and execution quality.<br/>
</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='text-dark' style={{fontSize:'1.2em'}}>What's included in an end-to-end interior solution?</b></Accordion.Header>
        <Accordion.Body>
         <p className='text-secondary fs-5'>Services can vary, but generally include:<br/>
<b>Design consultation and space planning:</b> Understanding your needs and creating tailored layouts.<br/>
<b>3D renderings and virtual tours:</b> Visualising the finished space before construction begins.<br/>
<b>Furniture, fixtures, and equipment (FF&E) procurement:</b> Sourcing and installing furniture, lighting, and equipment.<br/>
<b>Construction and renovation:</b> Handling any necessary structural or aesthetic changes.<br/>
<b>Project management and oversight:</b> Managing budgets, timelines, and communication throughout the project.<br/>
</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='text-dark' style={{fontSize:'1.2em'}}>Can I provide input on the design process?</b></Accordion.Header>
        <Accordion.Body>
         <p className='text-secondary fs-5'>Absolutely! Collaboration is key. Turnkey providers should work closely with you to understand your vision, brand identity, and functional needs. Share your ideas, preferences, and inspirations for a personalised space.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header><b className='text-dark' style={{fontSize:'1.2em'}}>Do you offer post-project maintenance and support?</b></Accordion.Header>
        <Accordion.Body>
         <p className='text-secondary fs-5'>Ask about warranty and maintenance services for furniture, fixtures, and installed systems.</p>
        </Accordion.Body>
      </Accordion.Item>
  
      
    </Accordion>
            </Col>
        </Row>
    </>
  )
}

export default Faq