import React,{useEffect} from 'react';
import '../styles/Main.css';
import './Thanks.css';
import { Col, Container, Row } from 'react-bootstrap'

function Thanks() {
  useEffect(() => {
   
    // Include the Google Analytics tracking script here
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11353838216';
    script.async = true;

    script.onload = () => {
      // Initialize Google Analytics when the script is loaded
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-11353838216');
      gtag('event', 'conversion', {'send_to': 'AW-11353838216/AyzSCLmYp4MZEIil96Uq'});
    };

    document.head.appendChild(script);

    // Clean up the script tag on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  return (
    <Container fluid>
    <Row className='firstRowSer'>
      <Col className='border-bottom border-dark border-2'>
        <h1 className="heading-title">THANK YOU!</h1>
      </Col>
    </Row>
    <Row className='backgroundRow'>
      <Col>
        <h2 className="text-center text-light thanksH2">Thank You For Your Enquiry</h2>
        <p className="text-center text-light thanksHP">Thank you for choosing Introspace for your interior design needs.</p>
      </Col>
    </Row>
  </Container>
  )
}

export default Thanks