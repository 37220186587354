import { Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import Thanks from './pages/Thanks';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Main/>} />
      <Route path='thank-you' element={<Thanks />} />
    </Routes>
  );
}

export default App;
