import React,{useState} from 'react';
import '../styles/Main.css';
import '../styles/Header.css';
import { Container, Row, Col, Navbar, Nav, Button, Offcanvas } from 'react-bootstrap';
import Mod from '../utils/Mod';

function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <Row style={{backgroundColor:'#30373e',justifyContent:'center',alignContent:'center',alignItems:'center'}} className='pt-0 pt-md-0 '>
        <Col className='col-12 col-md-6 d-none d-md-block'>
        <i class="fa-brands fa-facebook-f" style={{color:'#9da3a9',marginLeft:'1%',marginRight:'1%'}}></i>
        <i class="fa-brands fa-twitter" style={{color:'#9da3a9',marginLeft:'1%',marginRight:'1%'}}></i>
        <i class="fa-brands fa-pinterest" style={{color:'#9da3a9',marginLeft:'1%',marginRight:'1%'}}></i>
        <i class="fa-brands fa-youtube" style={{color:'#9da3a9',marginLeft:'1%',marginRight:'1%'}}></i>
        </Col>
        <Col className='col-12 col-md-2   d-none d-md-block align-items-center justify-content-center' >
        <a href="mailto:info@prominentspace.com" style={{color:'#9da3a9',textDecoration:'none'}}> <i class="fa-regular fa-envelope"></i>  info@prominentspace.com</a>
        </Col>
        <Col className='col-12 col-md-2 d-none d-md-block d-flex align-items-center justify-content-center'>
        <a href="tel:+919902902287"  style={{color:'#9da3a9',textDecoration:'none'}}><i class="fa-solid fa-phone-volume"></i>  +91 9902902287</a>
        </Col>
        <Col className='col-12 col-md-2 d-none d-md-block'>
            <Button className='headerButton' onClick={handleShow}>BOOK APPOINTMENT <i class="fa-solid fa-arrow-right-long"></i></Button>
        </Col>
    </Row>
        <Navbar expand={'sm'} className="bg-body-light pt-2 pb-2 pt-md-0 pb-md-0 headerNav">
      <Container fluid>
        <Navbar.Brand href="#" className='firstNav'>
          <img
            src={require("../assets/logo.png")}
            className='imgNav'
            // className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} /> */}
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          // placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
            <img
            src={require("../assets/logo.png")}
            className="imgNav"
            alt="React Bootstrap logo"
          />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3 text-dark">
              <Nav.Link href="#Home" className="text-dark" style={{fontSize:'1.2em',fontWeight:'600',color:'black'}}>
                Home
              </Nav.Link>
              <Nav.Link href="#services" className="text-dark" style={{fontSize:'1.2em',fontWeight:'600',color:'black'}}>
                Our Services
              </Nav.Link>
              <Nav.Link href="#Work"  className="text-dark" style={{fontSize:'1.2em',fontWeight:'600',color:'black'}}>
                How We Work
              </Nav.Link>
              <Nav.Link href="#Projects"  className="text-dark" style={{fontSize:'1.2em',fontWeight:'600',color:'black'}}>
                Our Projects
              </Nav.Link>
              {/* <Nav.Link href="#Contact" className="text-dark" style={{fontSize:'1.2em',fontWeight:'bold',color:'black'}}>
                Contact Us
              </Nav.Link> */}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
    <Mod show={show} handleClose={handleClose} />
    </>
  )
}

export default Header