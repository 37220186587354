import React,{ useState } from 'react';
import '../styles/Main.css';
import '../styles/FirstSec.css';
import Carousel from 'react-bootstrap/Carousel';
import { Button, Row, Col } from 'react-bootstrap';
import Contact from '../utils/Contact';
import Mod from '../utils/Mod';

function FirstSec() {
  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


  return (
    <>
    <Row className='firstRow'>
        <Col className='col-12 col-md-6 px-4'>
        <h2 className='heading-title-light mt-0 mt-md-5'>Ensuring a safe experience from design to installation</h2>
        <p className='paragraph-16-light'>We’re following all protocols to ensure your safety and vaccination drives are underway to ensure our employees are ready to meet you safely.</p>
        <div className='d-flex flex-column align-items-md-start align-items-center'>
{/* <Button className='main-buton-light'  onClick={handleShow}>Book A Consultation <i className="fa-solid fa-arrow-right-long"></i></Button> */}
</div>
        </Col>
        <Col className='col-12 col-md-3'>
       
        </Col>
        <Col className='col-12 col-md-3 firstCol2 mt-5 mt-md-0'>
        <Contact />
        </Col>
    </Row>
    <Mod show={show} handleClose={handleClose} />
</>
  )
}

export default FirstSec