import React, {useState} from 'react';
import '../styles/SecondSec.css';
import '../styles/Main.css';
import { Col, Row, Button } from 'react-bootstrap';
import Mod from '../utils/Mod';


function SecondSec() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Row className='ssRow'>
        <Col className='ssColFirst col-12 col-md-7 p-3 '>
    <img src={require('../assets/About_03.jpg')} className='firstImg'/>
        </Col>
        <Col className='ssColSecond col-12 col-md-5 p-4 marTop'>
            <p className='heading-paragraph'>What we offer</p>
            <h2 className='heading-title'>Interior Design & Space Planning</h2>
            <p className='paragraph-16'>Customized design solutions that reflect your brand’s vision and identity.</p>
            <Button className='main-buton'  onClick={handleShow}>Book a Consultation<i class="fa-solid fa-arrow-right-long"></i></Button>
        </Col>
      </Row>  
      {/* <Row className='ssSecondRow'>
        <Col className='col-12 col-md-4'>
          <Row className=''>
            <Col className='col-12 col-md-3'>
            <center>
          <img src={require('../assets/price-list.png')} className='icon-box-image marTop'/>
          </center>
            </Col>
            <Col className='col-12 col-md-9'>
            <h3 className='icon-box-title marTop'>Reasonable Prices</h3>
            <p className='icon-box-description marTop'>We produce furniture to fulfill needs of all people and offer it at affordable and fair prices</p>
            </Col>
          </Row>
        </Col>
        <Col className='col-12 col-md-4 '>
          <Row className=''>
            <Col className='col-12 col-md-3'>
            <center>
          <img src={require('../assets/price-list.png')} className='icon-box-image marTop'/>
          </center>
            </Col>
            <Col className='col-12 col-md-9'>
            <h3 className='icon-box-title marTop'>Reasonable Prices</h3>
            <p className='icon-box-description marTop'>We produce furniture to fulfill needs of all people and offer it at affordable and fair prices</p>
            </Col>
          </Row>
        </Col>

        <Col className='col-12 col-md-4 '>
          <Row className=''>
            <Col className='col-12 col-md-3'>
            <center>
          <img src={require('../assets/price-list.png')} className='icon-box-image marTop'/>
          </center>
            </Col>
            <Col className='col-12 col-md-9'>
            <h3 className='icon-box-title marTop'>Reasonable Prices</h3>
            <p className='icon-box-description marTop'>We produce furniture to fulfill needs of all people and offer it at affordable and fair prices</p>
            </Col>
          </Row>
        </Col>       
       
      </Row> */}
      <Mod show={show} handleClose={handleClose} />
    </>
  )
}

export default SecondSec