import React from 'react';
import '../styles/Main.css';
import '../styles/NinthSec.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import Slider from "react-slick";


function NinthSec() {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <>
        <Row className='ninRow'>
            <Col className='col-12 col-md-12'>
            {/* <p className='heading-paragraph' >QUALITY OF WORK</p> */}
            <h2 className='heading-title-light'>OUR CLIENTS</h2>
            <Slider {...settings}>
          <div>
           <img src={require('../assets/1-1.png')} className='imgForNin' />
          </div>
          <div>
          <img src={require('../assets/2.png')} className='imgForNin' />
          </div>
          <div>
          <img src={require('../assets/2-1.png')} className='imgForNin' />
          </div>
          <div>
          <img src={require('../assets/3.png')} className='imgForNin' />
          </div>
          <div>
          <img src={require('../assets/3-1.png')} className='imgForNin' />
          </div>
          <div>
          <img src={require('../assets/4.png')} className='imgForNin' />
          </div>
          <div>
          <img src={require('../assets/5.png')} className='imgForNin' />
          </div>
         
        </Slider>
            </Col>
          
        </Row>
    </>
  )
}

export default NinthSec