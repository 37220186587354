import React from 'react'
import '../styles/WhyChoose.css';
import '../styles/Main.css';
import { Col, Row } from 'react-bootstrap';
function WhyChoose() {
  return (
    <>
    <Row className='ftsRow'>
    
        <Col className='col-12 col-md-9'>
        <p className='heading-paragraph'>WHY CHOOSE US</p>
        <h2 className='heading-title'>At Prominent Space, we offer a comprehensive range of services</h2>

        </Col>
       <center>
        <Row className='mt-4'>
        <Col className='col-12 col-md-3 mt-4 mt-md-0 px-4'>
            <div  className='p-5 box-shadow' style={{backgroundColor:'#fff'}}>
            <center>
            <img src={require('../assets/project.png')} className='ftsImg'/>
                <h3 className='icon-box-title mt-4' >Design Consultation</h3>
                <p className='icon-box-description'>Our design journey begins with you. We believe that every space has a unique story to tell, our experienced designers are here to listen.</p>
                </center>
            </div>
        </Col>
        <Col className='col-12 col-md-3 mt-4 mt-md-0 px-4 '>
        <div  className='p-5 box-shadow' style={{backgroundColor:'#fff'}}>
            <center>
            <img src={require('../assets/management.png')} className='ftsImg'/>
                <h3 className='icon-box-title mt-4' >Project Management</h3>
                <p className='icon-box-description'>Embarking on an interior design project can be daunting, but with Prominent Space by your side, it becomes an exciting adventure.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                </center>
            </div>
        </Col>
        <Col className='col-12 col-md-3 mt-4 mt-md-0 px-4 '>
        <div  className='p-5 box-shadow' style={{backgroundColor:'#fff'}}>
            <center>
            <img src={require('../assets/furniture.png')} className='ftsImg'/>
                <h3 className='icon-box-title mt-4' >Furniture & Manufacturing</h3>
                <p className='icon-box-description'>We believe that furniture isn't just functional; it's an art form that enhances the ambiance of a space. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                </center>
            </div>
        </Col>
        <Col className='col-12 col-md-3 mt-4 mt-md-0 px-4 '>
        <div  className='p-5 box-shadow' style={{backgroundColor:'#fff'}}>
            <center>
            <img src={require('../assets/dollar.png')} className='ftsImg'/>
                <h3 className='icon-box-title mt-4' >Implementation & Evaluation</h3>
                <p className='icon-box-description'>Turning design concepts into reality requires precision and expertise. Our implementation team meticulously brings the designs to life.</p>
                </center>
            </div>
        </Col>

    </Row>
    </center>
    </Row>
  
</>
  )
}

export default WhyChoose