import React from 'react'
import { Container } from 'react-bootstrap'
import FirstSec from '../components/FirstSec'
import SecondSec from '../components/SecondSec'
import ThirdSec from '../components/ThirdSec'
import FifthSec from '../components/FifthSec'
import SixthSec from '../components/SixthSec'
import SeventhSec from '../components/SeventhSec'
import EigthSec from '../components/EigthSec'
import Footer from '../components/Footer'
import NinthSec from '../components/NinthSec'
import Header from '../components/Header'
import ForthSec from '../components/ForthSec'
import Faq from '../components/Faq'
import WeWork from '../components/WeWork'
import WhyChoose from '../components/WhyChoose'
import Services from '../components/Services'

function Main() {
  return (
    <Container fluid>
    <Header />
    <FirstSec />
        
        <WeWork />
        <SecondSec />
        <Services />
       <ForthSec />
       <WhyChoose />
        
        <SixthSec />
      
        {/* <EigthSec /> */}
        <NinthSec />
        <Faq />
        <Footer />
    </Container>
  )
}

export default Main