import React,{useState} from 'react';
import '../styles/Footer.css';
import '../styles/Main.css';
import { Col, Row, Button } from 'react-bootstrap';
import Mod from '../utils/Mod';

function Footer() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
        <Row className='footRow'>
            <Col>
                <Row className='pb-5'>
                <Col className='col-12 col-md-7 mx-auto'>
        <center>
        {/* <p className='heading-paragraph'>YOU DREAM IT, WE DESIGN IT</p> */}
        <h2 className='heading-title-light'>Your business has leveled up, but has your office space grown with you?</h2>
        <p className='paragraph-16-light'>Ready to level up your office</p>
        <Button className='main-buton-brown'  onClick={handleShow}>SCHEDULE A CALL <i class="fa-solid fa-calendar-days"></i></Button>

        </center>
        </Col>
                </Row>
                <Row className='border-top border-dark-subtle pt-5 pb-0'>
                <Col>
                    <center>
                        <p className='' style={{fontSize:'1em',color:'#fff'}}>© Copyright 2023 Prominent Space. All rights reserved.</p>
                    </center>
                </Col>
                </Row>
            </Col>
        </Row>
        <Mod show={show} handleClose={handleClose} />
    </>
  )
}

export default Footer