import React,{useState} from 'react';
import '../styles/SixthSec.css';
import '../styles/Main.css';
import { Col, Row, Button } from 'react-bootstrap';
import Mod from '../utils/Mod';

function SixthSec() {
  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
        <Row className='stsRow'>
            <Col className='col-12 col-md-6 px-4'>
            <p className='heading-paragraph'>About Us</p>
            <h2 className='heading-title-light'>Prominentspace interior design services</h2>
            <div className='d-flex flex-column align-items-md-start align-items-center'>
    <Button className='main-buton-light'  onClick={handleShow}>Call now <i className="fa-solid fa-arrow-right-long"></i></Button>
  </div>
            </Col>
            <Col className='col-12 col-md-6 stsCol2'>
            <p className='paragraph-16-light'>PROMINENT SPACE stands as a premier occupancy services firm in INDIA. Our expertise spans interior design, turnkey solutions, civil and interior services, MEP services, general contracting, and Loose Furniture provision. We’re revolutionising learning spaces with innovative approaches. With a global reach, we redefine learning environments through fresh perspectives and boundless creativity.</p>

            </Col>
        </Row>
        <Mod show={show} handleClose={handleClose} />
    </>
  )
}

export default SixthSec