import React,{useState, useRef} from 'react';
import '../styles/Main.css';
import './Contact.css';
import { Form, Row, Col, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const Result = () =>{
    return(
        <p>Your message has been successfully sent. We'll contact you soon</p>
    )
  };

function Contact() {
    const form = useRef();
    const [result, showResult] = useState(false);
    const navigation = useNavigate();
  
  
   
    const sendEmail = async  (e) => {
        e.preventDefault();
    
        
      
        emailjs.sendForm('service_brvkkjf', 'template_j8bjo3a', form.current, 'h06X-FKNULTBO91Es')
          .then((result) => {
              console.log(result.text);
              navigation('/thank-you');
          }, (error) => {
              console.log(error.text);
          });
          // e.target.reset();
          // showResult(true);
     
      
      e.target.reset();
      showResult(true);
  };
  
      // hide result after work 
  
      setTimeout(()=>{
        showResult(false);
      }, 5000);

  return (
    <div className='FormSection'>
    <h2 className=' text-center fs-4' style={{color:'#163664'}}>Get Free Interior Consultations</h2>
        <Form ref={form} onSubmit={sendEmail}>
        <Form.Control size="lg" type="text" placeholder="Name" className='my-3' required name='fullName'/>
        
        <Form.Control size="lg" type="number" placeholder="+91" className='my-3' required name='phone' />
       
        <Form.Control size="lg" type="email" placeholder="E-mail" className='my32' required  name='email'/>

        <Form.Control size="lg" type="text" placeholder="Company Name" className='my-3' required name='company'/>
        
        <Form.Select size="lg" className='my-3' required name='sqrt'>
             <option value="" disabled>Select Project Size</option>
             <option value="Less Than 2000 SQFT ">Less Than 2000 SQFT </option>
             <option value="2000-10000 SQFT">2000-10000 SQFT</option>
             <option value="Greater Than 10000 SQFT ">Greater Than 10000 SQFT </option>
        </Form.Select>

        <Form.Select size="lg" className='my-3' required name='budget'>
            <option value="" disabled>BudgetSelect Project Budget</option>
            <option value="Less Than 10 Lakhs">Less Than 10 Lakhs</option>
            <option value="10 Lakhs - 1 Crore">10 Lakhs - 1 Crore</option>
            <option value="Greater Than 1 Crore">Greater Than 1 Crore</option>
            <option value="Above 30 Lakhs">Above 30 Lakhs</option>
        </Form.Select>
        <Button type="submit" variant="dark" className='w-100' size="lg" style={{backgroundColor:'#163664'}}>Send Enquiry</Button>
        </Form>
        </div>
  )
}

export default Contact