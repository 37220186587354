import React,{ useState } from 'react';
import '../styles/WeWork.css';
import '../styles/Main.css';
import { Col, Row } from 'react-bootstrap';
import Mod from '../utils/Mod';

function WeWork() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
    <Row className='wsRow' id='Work'>
    <Col className='col-12 col-md-12'>
        <p className='heading-paragraph' style={{textAlign:'center'}}>HOW WE WORK</p>
        <h2 className='heading-title' style={{textAlign:'center'}}>Collaborate. Innovate. Achieve Together.</h2>

        </Col>
       <center>
        <Row>
        <Col className='col-12 col-md-3 mt-4 mt-md-0 mx-0 px-3 px-md-3'>
        <div  className='p-5' style={{backgroundColor:'#fff'}}>
            <p className='wcta_number'>01</p>
            <h3 className='icon-box-title mt-4' style={{textAlign:'left'}}>Define</h3>
            <p className='icon-box-description'  style={{textAlign:'left',marginBottom:'25%',marginTop:'10%'}}>This involves identifying the product concept and the requirements.</p>
            {/* <a  className='mt-5' style={{fontSize:'1em',fontWeight:'bold',color:'#30373e',textDecoration:'none'}}>READ MORE</a> */}
            </div>
        </Col>
        <Col className='col-12 col-md-3 mt-4 mt-md-0 px-3 px-md-3'>
        <div  className='p-5' style={{backgroundColor:'#fff'}}>
            <p className='wcta_number'>02</p>
            <h3 className='icon-box-title mt-4' style={{textAlign:'left'}}>Design</h3>
            <p className='icon-box-description'  style={{textAlign:'left',marginBottom:'25%',marginTop:'10%'}}>This involves creating a detailed plan or blueprint for the product.</p>
            {/* <a  className='mt-5' style={{fontSize:'1em',fontWeight:'bold',color:'#30373e',textDecoration:'none'}}>READ MORE</a> */}
            </div>
        </Col>
        <Col className='col-12 col-md-3 mt-4 mt-md-0 px-3 px-md-3'>
        <div  className='p-5' style={{backgroundColor:'#fff'}}>
            <p className='wcta_number'>03</p>
            <h3 className='icon-box-title mt-4' style={{textAlign:'left'}}>Develop</h3>
            <p className='icon-box-description'  style={{textAlign:'left',marginBottom:'25%',marginTop:'10%'}}>This involves creating a working prototype of the product.</p>
            {/* <a  className='mt-5' style={{fontSize:'1em',fontWeight:'bold',color:'#30373e',textDecoration:'none'}}>READ MORE</a> */}
            </div>
        </Col>
        <Col className='col-12 col-md-3 mt-4 mt-md-0 px-3 px-md-3'>
        <div  className='p-5' style={{backgroundColor:'#fff'}}>
            <p className='wcta_number'>04</p>
            <h3 className='icon-box-title mt-4' style={{textAlign:'left'}}>Deploy</h3>
            <p className='icon-box-description'  style={{textAlign:'left',marginBottom:'25%',marginTop:'10%'}}>this involves setting up the production line, ordering and sourcing materials etc.</p>
            {/* <a  className='mt-5' style={{fontSize:'1em',fontWeight:'bold',color:'#30373e',textDecoration:'none'}}>READ MORE</a> */}
            </div>
        </Col>
        </Row></center>
      
       
    </Row>
</>
  )
}

export default WeWork